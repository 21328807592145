.theme-main-nav {

  .navigation-social {
    position: absolute;
    right: 14%;
    .nav-social {
      .pageElement {
        margin-top: 8px;
      }
      .sn-social-media-list {
        .icon-facebook, .icon-twitter, .icon-youtube, .icon-instagram {
          background-color: $aztec-red;
          &:hover {
            background-color: $aztec-dark-gray;
          }
        }
        .sn-social-media-icon {
          height: 1.2em;
          width: 1.2em;
          &:before {
            font-size: 0.7em;
            line-height: 1.65em;
            width: 1.75em;
          }
        }
      }
    }
  }
}

#topNav {
  .theme-nav-style-dropdown {
    ~ .theme-nav-dropdown {
        background-color: rgba(255, 255, 255, 0.8);
        border-bottom: none;
        border-top: none;
        font-size: 13px;

      > .theme-nav-item {
        border-bottom: none;
        > .theme-nav-link {
            color: #747474;
            &:not(x):hover {
              background: #eeeeee;
              color: $aztec-red;
            }
        }

      }
    }
  }
}

// Edit mode styling

body.edit_mode {
  .theme-main-nav {
    .navigation-social {
      right: 14%;
      z-index: 100;
      .nav-social { float: right; }
      .drag { display: none; }
      .columnBounds { display: none; }

      &:before {
        content: "Please Edit on Assets Page";
        display: block;
        height: 47px;
        width: 400px;
        background-color: rgba(0,0,0,0.6);
        position: absolute;
        z-index: 101;
        line-height: 46px;
        text-indent: 40px;
        transform: translateX(320px);
        transition: transform 200ms ease-out;
      }

      &:hover {
        &:before {
          transform: translateX(-30px);
        }
      }
    }
  }
}
