.news-events {
  .aggHeader {
    color: $aztec-red;
    background: none;
    box-shadow: none;
    font-weight: 900;
    padding-left: 0;
    font-size: 36px;
  }
  .item {
    background-color: #eeeeee;
    padding: 10px;
    // border-bottom: 1px solid $aztec-gray;
    .dateAuthor { display: none; }
    &.condensed {
      img { width: 130px; }
      .details {
        h4 {
          font-size: 20px;
          line-height: 1.2;
          width: 80%;
          margin: 10px 0 5px 0;
        }
      }
      .teaser {
        font-size: 0.9em;
        font-weight: 100;
        text-transform: none;
        line-height: 1.25;
        color: $aztec-dark-gray;
        margin: 0.1em 0 0.6em 0;
      }
    }
    .commentsAndMore {
      .readMore a {
        font-size: 0.8em;
        font-weight: 100;
        color: #ffffff;
        border-bottom: none;
      }
    }
    &.extendedOptions {
      border-style: none;
      // padding-right: 30px;
      padding: 5px 30px 5px;
    }
  }
  .item:not(.extendedOptions) {
    &:after {
      content: "";
      width: 90%;
      position: absolute;
      border-bottom: 1px solid $aztec-gray;
      transform: translateY(8px);
    }
  }
}

html.has-mobile-nav {
  .news-events {
    .item {
      display: flex;
      &.condensed {
        .details {
          h4 {
            font-size: 16px;
            margin: 0 0 5px 0;
          }
        }
        .teaser { display: none; }
      }
      &:after {
        width: 80%;
        transform: translateY(-10px);
      }
      &.extendedOptions {
        padding: 10px;
        &:after {
          content: "";
          width: 80%;
          position: absolute;
          border-bottom: 1px solid $aztec-gray;
          transform: translateY(-10px);
        }
      }
    }
    .item:first-of-type {
        &:after { display: none; }
    }
  }
}
html.tablet-break {
  .news-events {
    .column { padding: 0 10px; }
  }
}
