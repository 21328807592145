body:not(.edit_mode) {
  .slide-show-cta {
    position: absolute;
  }
}

.slide-show-cta {
  // position: absolute;
  width: 390px;
  display: flex;
  flex-direction: column;
  top: 10%;
  right: 5%;

  .column {
    width: 100%;

    .hero-cta {
      .pageElement {
        margin-top: 0;
        margin-bottom: 10px;
      }
      .sn-call-to-action {
        height: 90px;
        background-color: $aztec-red;
        img { display: none; }
        h4 {
          font-size: 22px;
          font-weight: 500;
          letter-spacing: 1px;
          line-height: 65px;
        }

        .sn-call-to-action-overlay {
          &:before {
            content: "";
            display: block;
            position: absolute;
            height: 0;
            width: 0;
            bottom: 0;
            right: 0;
            border-top: 15px solid transparent;
            border-left: 15px solid transparent;
            border-right: 15px solid #000000;
            border-bottom: 15px solid #000000;
            transition: transform 300ms ease-out;
            transform-origin: bottom right;
          }
          &:after {
            content: "";
            position: absolute;
            display: block;
            height: 0;
            width: 0;
            bottom: 5px;
            right: 5px;
            border-left: 5px solid #ffffff;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-right: 0px solid transparent;
            transform-origin: bottom right;
            transition: transform 300ms ease-out;
          }
        }
        &:hover {
          .sn-call-to-action-overlay {
            &:before {
              transform: scale(1.6);
            }
            &:after {
              transform:
                scale(1.6)
                translateY(-2px)
                translateX(-2px);
            }
          }
        }
      }
    }
  }
}

// 2. Mobile styling

html.has-mobile-nav {
  .slide-show-cta {
    width: 100%;
    position: relative;
    right: 0;
    top: 0;
    .column {
      .hero-cta {
        .pageElement { margin-bottom: 5px; }
        .sn-call-to-action {
          height: 50px;
          .sn-call-to-action-overlay {
            padding: 10px;
          }
          h4 {
            font-size: 1.8em;
            line-height: 50px;
          }
          &:before {
            top: 17px;
            left: 10px;
            border-bottom: 2px solid #ffffff;
          }
        }
      }
    }
  }
}

// 3. Edge Case Breakpoints

@media only screen and (min-width: 1024px) and (max-width: 1135px) {
  .slide-show-cta { top: 8%; }
}
