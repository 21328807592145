.tabs-links {
  background-image: url(http://assets.ngin.com.s3.amazonaws.com/site_files/13841/_site/images/band-background.jpg);

  .premiere-tab {
    .contentTabs {
      margin-bottom: 0;
      li {
        background-color: $aztec-gray;
        color: #ffffff;
        a { color: #ffffff; }
        &.selected {
          background-color: #ffffff;
          border-bottom: 5px solid #ffffff;
          a { color: $aztec-red; }
        }
        span { transform: translateY(5px); }
      }
    }
    .tabContainer {
      background-color: #ffffff;
    }
  }
  .quick-link-title {
    margin-top: 20px;
    margin-bottom: 5px;

    .pageElement {
      margin-bottom: 0;
      h3 {
        margin-bottom: 0.1em;
        color: #999999;
      }
    }
  }
  .custom-link {
    .linkElement {
      margin: 0 0 5px 0;
      h4 {
        a {
          box-shadow: none;
          padding-left: 30px;
          font-weight: 400;
        }
      }
      .goToLink {
        &:hover {
          &:after { display: none; }
          &:before {
            transform:
            translateX(10px)
            translateY(150%);
          }
        }
        &:before {
          content: "";
          display: block;
          height: 0;
          width: 0;
          border-left: 5px solid #ffffff;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          transition: transform 200ms linear;
          transform:
            translateY(150%)
            translateX(5px);
        }
        &:after {
          display: none;
        }
      }
    }
  }
}

.twitterFeedElement {
  .tweet-container {
    border: none;
  }
  .header-screen-name:before { border: 1px solid $aztec-red; }
  .tweet-link, .tweet-name:hover, .tweet-hover:hover, .tweet-screen-name,
   .tweet-date, .tweet-text a, .tweet-text a:hover, .tweet-date:hover, .tweet-url, .tweet-url:hover {
     color: $aztec-red;
   }
}

.fb-page {
  iframe {
    margin-left: 10%;
  }
}

html.has-mobile-nav {
  .tabs-links {
    .quick-link-title {
      margin-top: 0;
    }
  }
}

html.tablet-break {
  .tabs-links-adjust {
    .column { padding: 10px; }
    .tabbedElement {
      .layoutContainerTabs { display: flex; }
    }
  }
}
