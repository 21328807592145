
// 1. General styling for the full-band media slider

.hero-slider {
  .mediaSlider {
    margin-top: 0;

    .slide-title {
      width: 50%;
      font-size: 65px;
      font-weight: 900;
      line-height: 0.9em;
      text-transform: uppercase;
      margin-bottom: 20px;

      &:before{
        content: "";
        display: block;
        margin-bottom: 20px;
        position: relative;
        width: 100px;
        height: 10px;
        background-color: $aztec-red;
      }
    }

    .slide-description {
      width: 50%;
      line-height: 120%;
      font-size: 1.5em;
      margin-bottom: 40px;
      margin-top: 0;
      text-shadow: none;
    }

    .sn-media-slider {
      .slider {
        margin-bottom: 0;
        .slide-text {
          &.bottom-left {
            // padding-top: 75px;
            vertical-align: middle;
          }
        }
        &.bottom-left-paging {
          .slider-pagination {
            bottom: 27%;
            left: 15%;
            width: auto;
            .paging-item {
              height: 13px;
              width: 13px;
            }
          }
        }
      }

      .slides {
        .slide {
          .slide-overlay {
            padding: 5% 15% 4% 15%;
            background: linear-gradient(to top, rgba(0,0,0,.8) 10%, rgba(0,0,0,0) 50%);
          }
        }
      }
      .flex-direction-nav {
        .flex-prev {
          left: 0;
          background-color: rgba(0,0,0,0.6);
          border-radius: 0 5px 5px 0;
          width: 60px;
          transform: translate(-20px, -16px);
          &:before {
            position: relative;
            left: 30px;
          }
          &:hover {
            transform: translateX(0) translateY(-16px);
          }
        }
        .flex-next {
          right: 0;
          background-color: rgba(0,0,0,0.6);
          border-radius: 5px 0 0 5px;
          width: 60px;
          transform: translate(20px, -16px);
          text-indent: 10px;
          &:before {
            position: relative;
            right: 30px;
          }
          &:hover {
            transform: translateX(0) translateY(-16px);
          }
        }
        a {
          opacity: 1;
          &:before {
            font-size: 40px;
            text-shadow: none;
          }
        }
      }
    }
  }
}

// Removing the padding that is built into ngin ----- >
#panelTwo {
  padding-left: 0;
  padding-right: 0;
  .full-width-hero-slider {
    .column {
      padding: 0;
    }
  }
}

// 2. Mobile Styling

html.has-mobile-nav:not(.tablet-break) {
  .hero-slider {
    .mediaSlider {
      p { margin-bottom: 0; }
      .slide-title {
        font-size: 18px;
        width: 100%;
        margin-bottom: 5px;
        &:before {
          display: none;
        }
      }
      .slide-description {
        font-size: 12px;
        width: 100%;
        margin-bottom: 0;
      }
      .sn-media-slider {
        .flex-direction-nav {display: none;}
        .slides .slide .slide-overlay {
          padding: 5% 5% 5% 10%;
          background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 30%, transparent 60%);
        }
        .slider {
          .slide-text {
            &.bottom-left { vertical-align: bottom; }
          }
          &.bottom-left-paging {
            .slider-pagination {
              // bottom: 10px;
              display: none;
            }
          }
        }
      }
    }
  }
}

// 3. Tablet Styling

html.tablet-break {
  .hero-slider {
    .mediaSlider {
      p { margin-bottom: 0; }
      .slide-title {
        font-size: 24px;
        width: 70%;
        margin-bottom: 5px;
        &:before {
          // display: none;
          height: 5px;
        }
      }
      .slide-description {
        font-size: 1em;
        margin-bottom: 0;
      }
      .sn-media-slider {
        .flex-direction-nav { display: none; }
        .slider {
          &.bottom-left-paging {
            .slider-pagination {
              bottom: 25%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1380px) {
  .hero-slider .mediaSlider .sn-media-slider .slider.bottom-left-paging .slider-pagination { bottom: 20%; }
  .hero-slider .mediaSlider .slide-title { font-size: 50px; }
}
