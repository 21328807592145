html.page-manager-visible {
  .snFooterContainer {
    transform: translateX(-60px);
  }
}

.global-footer {
  background-color: $aztec-dark-gray;
  padding: 20px;
  .column:nth-child(2) {
    display: flex;
    width: 40%;
    // justify-content: space-around;
  }
  .footer-logo {
    img {
      border: none;
      width: 100px;
      height: auto;
    }
  }

  .footer-links {
    margin-right: 90px;

    h3 {
      color: #ffffff;
      font-size: 16px;
      margin-bottom: 20px;
      font-weight: 600;
    }
    a {
      color: #bababa;
      font-size: 13px;
      font-weight: 100;
      &:hover {
        color: $aztec-red;
        text-decoration: none;
      }
    }
    p {
      margin-bottom: 5px;
      text-transform: uppercase;
    }
  }

  .footer-contact {
    h3 {
      color: #ffffff;
      font-size: 16px;
      margin-bottom: 20px;
      font-weight: 600;
    }
    p {
      color: #bababa;
      font-size: 13px;
      margin-bottom: 2px;
    }
  }

  .footer-social {
    h3 {
      color: #ffffff;
      font-size: 16px;
      margin-bottom: 20px;
    }
    .pageElement {
      margin-bottom: 0;
    }
    .sn-social-media-list {
      margin-top: 0;
      .icon-facebook, .icon-twitter, .icon-youtube, .icon-instagram {
        background-color: #000000;
        &:hover {
          background-color: $aztec-red;
        }
      }
      .sn-social-media-icon {
        height: 1.6em;
        width: 1.6em;
        &:before {
          font-size: 0.9em;
          line-height: 1.85em;
          width: 1.75em;
        }
      }
    }
  }
}

.snFooterContainer {
  max-width: none;
  background-color: $aztec-dark-gray;
  position: absolute;
}

#siteFooter {
  ul {
    display: flex;
    justify-content: center;
  }
}

// mobile styling fix

html.has-mobile-nav {
  .global-footer {
    display: flex;
  }
}

@media only screen and (max-width: 586px) {
  html.has-mobile-nav {
    .global-footer {
      flex-wrap: wrap;
    }
  }
}
@media only screen and (max-width: 415px) {
  html.has-mobile-nav {
    .global-footer {
      flex-direction: column;
    }
  }
}

// edit mode styling
body.edit_mode {
  .snFooterContainer {
    overflow: hidden;
    .columnBounds { border: none; }
    .elementBar { display: none; }
    .drag {
      display: flex;
      float: left;
    }

    &:before {
      content: "Please Edit this content on the Assets Page";
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: rgba(0,0,0,0.6);
      z-index: 100;
      text-align: center;
      line-height: 200px;
      font-size: 48px;
      color: #fff;
      transform: translateY(110%);
      transition: transform 200ms ease-out;
    }
    &:hover {
      &:before {
        transform: translateY(0);
      }
    }
  }
}
