.news-events {
  .custom-events {
    .aggHeader {
      padding-right: 0;
    }
    .vevent {
      background-color: #eeeeee;
      padding: 10px;
      border-style: none;

      .dateImage {
        background-color: #ffffff;
        padding: 0;
        margin: 0 .5em 0 0;
        &:after {
          display: none;
        }
        .month {
          color: #ffffff;
          font-size: 18px;
          font-weight: 100;
          background-color: $aztec-red;
          padding: 10px 0;
        }
        .date {
          color: $aztec-red;
          padding-bottom: 5px;
        }
      }
      .summary {
        margin-top: 5%;
      }
      .details {
        margin-top: 10px;
        .time {
          display: block;
          float: left;
          color: $aztec-dark-gray;
          font-size: 12px;
          a { display: none; }
        }
        .location {
          display: block;
          text-transform: none;
          color: $aztec-dark-gray;
          font-size: 12px;
          font-weight: 100;
          &:before {
            content: "- \00a0";
            display: inline-block;
          }
        }
        .tags { display: none; }
      }
    }

    .vevent:nth-child(5){
      display: none;
    }
    .extendedOptions {
      .goToLink {
        background-color: #eeeeee;
        margin-right: -25px;
        &:before { display: none; }
      }
      .rssLink { display: none; }
      .iCalLink { display: none; }
    }
  }
}

html.has-mobile-nav {
  .news-events {
    .custom-events {
      .vevent {
        .dateImage { float: left; }
        .summary { margin: 0; }
      }
      .extendedOptions {
        &:after {
          width: 85%;
          transform:
            translateY(-20px)
            translateX(-50px);
        }
      }
    }
  }
}
