.theme-search-wrapper {
  height: 30px;
}

.theme-search-bar {
  box-sizing: content-box;
  height: $site-tagline-height;

  .theme-search-box {
    position: relative;
    display: inline-block;
    margin: -$site-search-height 0;
    overflow: hidden;
    @include flex(0,0,auto);
    height: $site-search-height;
    &:before {
      content: "\f002";
      font-family: FontAwesome;
      display: inline-block;
      position: absolute;
      right: 0;
      // padding-right: $site-search-spacing;
      padding-right: 0;
      transform:
        translateX(-70px)
        translateY(-6px);
      transition:
        transform 200ms ease-in-out,
        color 200ms ease-in-out;
    }
    &:hover {
      &:before {
        transform:
          translateX(-220px)
          translateY(-6px);
        color: $link-color;
      }
    }

    &.focus-search {
      &:before {
        transform:
        translateX(-220px)
        translateY(-6px);
      color: $link-color;
      }
      .theme-search-input:not(:focus) { background: $site-search-submit-border-color; }
    }

    ~ .theme-search-box { margin-left: $site-search-spacing; }
  }

  .theme-search-input,
  .theme-search-submit,
  .theme-search-clear {
    float: left;
    height: $site-search-height;
    // line-height: $site-search-height;
    line-height: 30px;
    transition: background $transition-linear;
  }

  .theme-search-input,
  .theme-search-clear { font-size: $site-search-input-font-size; }

  .theme-search-input {
    background: transparent;
    border: none;
    // border-right: 1px solid #ffffff;
    width: 200px;
    padding: 0 ($site-search-height - $site-search-border-width * 2) 0 $site-search-spacing;
    margin-top: 2px;
    text-align: right;
    font-family: $fontTwo;
    color: $hover-color;

    &:hover { background: $site-search-submit-border-color; }
    &::-ms-clear { display: none; }
    &:focus {
      outline: none;
      background: $site-search-input-background-focus;
    }
  }

  .theme-search-submit {
    cursor: pointer;
    color: $site-search-submit-color;
    // font-size: $site-search-submit-font-size;
    font-size: 11px;
    text-transform: uppercase;
    border: none;
    padding: 0 $site-search-spacing;
    transition: color 200ms linear;

    &:focus,
    &:hover {
      background: none;
      color: $link-color;
    }
    &:before {
      content: "";
      display: inline;
      position: absolute;
      height: 14px;
      border-right: 1px solid #ffffff;
      transform:
        translateX(-11px)
        translateY(8px);
    }
  }

  .theme-search-clear {
    float: left;
    text-decoration: none;
    text-align: center;
    margin-left: 0 - $site-search-height - $site-search-border-width;
    margin-right: $site-search-border-width;
    width: $site-search-height;

    &:before {
      content: "✕";
      color: #9ca8af;
      font-family: FontAwesome;
      font-size: $site-search-input-font-size;
    }
  }
}
