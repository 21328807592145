#siteContainer {
  &, #siteContainer.container-fluid {
    padding: 15px 0;
    // width: 100%;
    max-width: $maxWidth;
    min-height: 100%;
    min-height: 100vh;
    min-height: calc(100vh - #{$nav-placeholder-height} - #{$site-footer-height} - #{$account-nav-height-desktop});
    margin: 0 auto;
    background: #fff;
  }

  @media only screen and (max-width: 1024px) { max-width:1024px; }

  #displayBodyHeader:not(.displayBodyHeader-image) {
    padding: 15px;
    font-size: 2.25em;
    font-weight: 600;
    border-bottom: $borderWidth solid $borderColor;
    text-transform: initial;
    max-width: 1000px;
    margin: 50px auto 10px auto;
  }

  #yieldContent.row { margin: auto; }

  #panelOne { padding: 0; }

  .yieldPageContent { padding:0; }
}

body:not(.home) {
  #siteContainer {
    padding-top: 80px;
  }
}

body.home {
  #siteContainer{
    &, #siteContainer.container-fluid {
      width: 100%;
      max-width: none;
      padding-top: 10px;
    }
  }
}

body.home.edit_mode {
  #siteContainer {
    padding-top: 70px;
  }
}

body#site-guide {
  #siteContainer{
    &, #siteContainer.container-fluid {
      width: 100%;
      max-width: none;
      padding-top: 30px;
    }
  }
}

html.has-mobile-nav {
  body.home {
    #siteContainer {
      padding-top: 0;
    }
  }
}
