.sponsor-slider {

  .sponsor-left {
    display: block;
    position: absolute;
    left: 10%;
    height: 100px;
    width: 50px;
    cursor: pointer;
    // transform: translateY(100%);
    transform: translateY(65px);
    z-index: 100;
    &:before {
      content: "\f104";
      display: block;
      position: absolute;
      font-family: FontAwesome;
      font-size: 28px;
      color: $hover-color;
      transition:
        color 200ms linear,
        transform 200ms linear;
    }
    &:hover::before {
      color: $accent-color;
      transform: scale(1.1);
    }
  }

  .sponsor-right {
    display: block;
    position: absolute;
    right: 10%;
    height: 100px;
    width: 50px;
    cursor: pointer;
    transform:
      translateY(70px);
    // transform:
    //   translateY(100%);
    z-index: 100;
    &:before {
      content: "\f105";
      display: block;
      position: absolute;
      right: 0;
      font-family: FontAwesome;
      font-size: 28px;
      color: $hover-color;
      transition:
        color 200ms linear,
        transform 200ms linear;

    }
    &:hover::before {
      color: $accent-color;
      transform: scale(1.1);
    }
  }

  .column {
    display: flex;
    overflow: hidden;
    justify-content: flex-start;
    align-items: center;
  }

  .sponsor-logo {
    min-width: 250px;
    height: auto;
    transition: transform 400ms ease-in-out;
  }
  img {
    width: auto;
    min-height: 60px;
    max-width: 200px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    border: none;
  }
}

// mobile styling {
html.has-mobile-nav {
  .sponsor-slider {
    .column { padding: 0; }
  }
}


// tablet styling

html.tablet-break {
  .sponsor-slider {
    .column { padding: 0; }
    width: 80%;
    .sponsor-logo {
      img { width: 85% }
    }
    .sponsor-right { right: 50px; }
    .sponsor-left { left: 50px; }
  }
}

// breakpoints

@media only screen and (max-width: 1400px){
  .sponsor-slider {
    .sponsor-right { right: 50px; }
    .sponsor-left { left: 50px; }
  }
}

@media only screen and (max-width: 1130px){
  .sponsor-slider {
    .sponsor-right { right: 20px; }
    .sponsor-left { left: 20px; }
  }
}
